@font-face {
    font-family: "Cairo";
    src: local("Cairo"),
        url("./assets/fonts/Cairo/Cairo-VariableFont_slnt,wght.ttf") format("truetype");
    unicode-range: U+0600-06FF;
}

@font-face {
    font-family: "Inter";
    src: local("Inter"),
        url("./assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf") format("truetype");
}

body {
    font-family: "Cairo", "Inter", sans-serif !important;
}