// Color variables

$dark-blue: #007DAD;
$primary-blue-hover: #0095CF;
$primary-blue: #009FDD;
$navy: #F5B335;
$navy-hover: #F5B335;
$teal: #00BED6;
$dark-teal: #008293;
$dark-teal-hover: #007A89;
$red-orange: #FF5C35;
$dark-red-orange: #D73F1B;
$dark-red-orange-hover: #CD3B19;
$pink: #F6A7D7;
$dark-pink: #A55D88;
$dark-pink-hover: #9E5682;
$positive: #36B37E;
$dark-positive: #1F865A;
$dark-positive-hover: #1B7E54;
$critical: #FFAB00;
$dark-critical: #A06C00;
$dark-critical-hover: #986600;
$negative: #FF3D31;
$dark-negative: #E22A1F;
$dark-negative-hover: #D92A20;
$lightyellow: #FFFBF5;
$light-blue-hover: #DFEFF5;
$light-navy: #F4F5FC;
$light-navy-hover: #E9EBFC;
$light-teal: #F0FBFD;
$light-teal-hover: #E1F7FB;
$light-red-orange: #FFF5F3;
$light-red-orange-hover: #FFEEEB;
$light-pink: #FEFAFD;
$light-pink-hover: #FDF0FA;
$light-positive: #F3FBF7;
$light-positive-hover: #E5F9EF;
$light-critical: #FFFAF0;
$light-critical-hover: #FAF2E3;
$light-negative: #FFF4F3;
$light-negative-hover: #FBEAE9;
$grey-100: #2C2926;
$grey-80: #464E5B;
$grey-64: #6B727C;
$grey-32: #B5B8BD;
$grey-08: #EDEEEF;
$grey-04: #F6F6F7;
$grey-02: #FAFBFB;
$white: #FFFFFF;
$business-shadow: #F5B335;
$business-10: #ECEFF9;
$business-20: #D9DEF4;
$business-40: #B3BEE9;
$business-5: #F6F7FC;
$quara-blue: #201747;

$colors : (
'dark-blue': $dark-blue,
'primary-blue-hover': $primary-blue-hover,
'primary-blue': $primary-blue,
'navy': $navy,
'navy-hover': $navy-hover,
'teal': $teal,
'dark-teal': $dark-teal,
'dark-teal-hover': $dark-teal-hover,
'red-orange': $red-orange,
'dark-red-orange': $dark-red-orange,
'dark-red-orange-hover': $dark-red-orange-hover,
'pink': $pink,
'dark-pink': $dark-pink,
'dark-pink-hover': $dark-pink-hover,
'positive': $positive,
'dark-positive': $dark-positive,
'dark-positive-hover': $dark-positive-hover,
'critical': $critical,
'dark-critical': $dark-critical,
'dark-critical-hover': $dark-critical-hover,
'negative': $negative,
'dark-negative': $dark-negative,
'dark-negative-hover': $dark-negative-hover,
'lightyellow': $lightyellow,
'light-blue-hover': $light-blue-hover,
'light-navy': $light-navy,
'light-navy-hover': $light-navy-hover,
'light-teal': $light-teal,
'light-teal-hover': $light-teal-hover,
'light-red-orange': $light-red-orange,
'light-red-orange-hover': $light-red-orange-hover,
'light-pink': $light-pink,
'light-pink-hover': $light-pink-hover,
'light-positive': $light-positive,
'light-positive-hover': $light-positive-hover,
'light-critical': $light-critical,
'light-critical-hover': $light-critical-hover,
'light-negative': $light-negative,
'light-negative-hover': $light-negative-hover,
'grey-100': $grey-100,
'grey-80': $grey-80,
'grey-64': $grey-64,
'grey-32': $grey-32,
'grey-08': $grey-08,
'grey-04': $grey-04,
'grey-02': $grey-02,
'white': $white,
'business-shadow': $business-shadow,
'business-10': $business-10,
'business-20': $business-20,
'business-40': $business-40,
'business-5': $business-5,
'quara-blue': $quara-blue,
);

@each $color,
$value in $colors {
    
    .#{$color} {
        color: $value;
    }

    .bg-#{$color} {
        background-color: $value;
    }
}