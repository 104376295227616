@import '../../scss/index.scss';

.rmdp-wrapper{
  padding: 10px;
}

.rmdp-week-day{
  color: $grey-32 !important;
}


.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden):not(.end):not(.start):not(.rmdp-selected) span:hover {
  background-color: $light-critical !important;
  color: #000 !important;
}

.end > span:hover,.start > span:hover {
  background-color: $critical !important;
  color: $white !important;
}

.rmdp-left i{
  border: solid black !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-right i{
  border: solid black !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover{
  box-shadow: none !important;
  background-color: $light-critical !important;
}

.rmdp-range {
  box-shadow: none !important;
  border-radius: 0 !important;
  background-color: $light-critical !important;
  color: #000 !important;
}


.rmdp-today span{
  background-color: unset !important;
  color: unset !important;
}

.rmdp-range.start {
  border-radius: 10px 0px 0px 10px !important;
  background-color: $critical !important;
  color: $white !important;

}

.rmdp-range.end {
  border-radius: 0px 10px 10px 0px !important;
  background-color: $critical !important;
  color: $white !important;
}

.rmdp-range.end.start, .rmdp-selected {
  border-radius: 10px !important;
  background-color: $critical !important;
  color: $white !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: unset !important;
  box-shadow: unset !important;
  color: unset !important;
}

.rmdp-input{
  border: unset !important;
  padding: 22px 12px !important;
  color: $grey-32 !important;
  background-color: $grey-04 !important;
  font-size: 14px !important;
  width: 100%;
  background: url('../../assets/icons/calender.svg') no-repeat 96% 50%;
  cursor: pointer;
}

.rmdp-container {
  width: 100% !important;
}


