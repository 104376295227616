.multi-range-slider {
	border: none !important;
	box-shadow: none !important;
}

.multi-range-slider .bar-inner {
  background-color: #f5b336 !important;
  justify-content: space-between;
  border: none !important;
  box-shadow: none !important;
}

.multi-range-slider .bar-right {
  background-color: #f0f0f0 !important;
  border-radius: 0px 10px 10px 0px;
  box-shadow: none !important;
}

.multi-range-slider .bar-left {
  background-color: #f0f0f0 !important;
  border-radius: 0px 10px 10px 0px;
  box-shadow: none !important;
}

.multi-range-slider .thumb::before {
  content: '|||' !important;
  background-color: inherit;
  position: absolute;
  width: 25px !important;
  height: 25px !important;
  border: #f5b336 solid 2px !important;
  box-shadow: none !important;
  border-radius: 50%;
  z-index: 1;
  margin: -8px -12px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.8;
  font-weight: bold;
  text-align: center;
}

.multi-range-slider .thumb .caption * {
	text-align: center;
	background-color: transparent !important;
	color: black !important;
	box-shadow: none !important;
}

.multi-range-slider .caption {
	bottom: -25px !important;
  display: flex !important;
}
