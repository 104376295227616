// any global custom styles or theme overrides should be added here

.dropdown-item {
   color: $grey-100 !important;
   font-weight: 400 !important;
  }

.search-input {
   @media (min-width: 768px) {
      min-width: 20rem;
   }
   @media (min-width: 992px) {
      min-width: 30rem;
   }
}